import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAL5PsIJnyLgRxVnhhJ4awj6dLhFSPNZGQ",
  authDomain: "csneolab-6ff28.firebaseapp.com",
  projectId: "csneolab-6ff28",
  storageBucket: "csneolab-6ff28.appspot.com",
  messagingSenderId: "49740716658",
  appId: "1:49740716658:web:df8e6779da7bcd705b80eb",
  measurementId: "G-HPE8TKPJ3J"
};


function App() {
  return (
    <div className="App">
      <header className="App-header">
        12345678910
      </header>
    </div>
  );
}

export default App;
